import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
// import { useGetPaymentQuery } from '../../services/paymentApi'
import Nav from '../../components/layout/Nav'
import { IoAlertCircleOutline, IoCheckmarkCircleOutline } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import Bill from '../../components/payment/Bill'

function Invoice() {
  const location = useLocation()
  const [data, setData] = React.useState()
  const navigate = useNavigate()
  const currentUser = useSelector((state) => state.user.currentUser)
  // const { data } = useGetPaymentQuery(params.id)
  // console.log('data', data)
  React.useEffect(() => {
    setData(location?.state?.key)
  }, [data])
  return (
    <div>
      <Nav />
      <div className="pt-36 px-5 lg:px-72">
        <div>
          {' '}
          <h5 className="text-[25px] font-roboto">Status de paiement</h5>
          {/* <p className="text-[12.5px] font-light">
            Commande {data?.order?._id?.slice(-6)}
          </p> */}
        </div>
        <div className="py-5">
          <div className="flex flex-col gap-3 items-center justify-center">
            {/* {data?.message && (
              <IoAlertCircleOutline className="text-[50px] text-red-500" />
            )}{' '} */}
            {data === '1' && (
              <div>
                <Bill data={data} />
                <IoCheckmarkCircleOutline className="text-[50px] text-green-500" />
                <p>{data?.payments[0]?.message}</p>
                <button
                  onClick={(e) => {
                    e.preventDefault()
                    navigate(`/orders/me/${currentUser?._id}`)
                  }}
                  className="flex gap-2 items-center bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                >
                  Suivre les commandes
                </button>
              </div>
            )}
            {data === '0' && (
              <div className="flex flex-col ites-center justify-center gap-3">
                <IoAlertCircleOutline className="text-[50px] text-red-500 self-center" />
                <p className="self-center">Le paiement a échoué</p>
                <button
                  onClick={(e) => {
                    e.preventDefault()
                    navigate(-1)
                  }}
                  className="flex gap-2 items-center bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                >
                  Réessayer le paiement
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Invoice
