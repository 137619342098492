import { IoCallOutline } from 'react-icons/io5'

export default function CallUser({ user, title, isDeliver, color }) {
  if (!user?.phone && !user?.verified_phone) return null
  console.log(
    'phone:',
    user?.verified_phone ? user?.verified_phone : user?.phone
  )
  let callphone = (e) => {
    e.preventDefault()
    let phone = user?.verified_phone ? user?.verified_phone : user?.phone
    window.location.href = `tel:${phone}`
  }

  return (
    <button
      className={`flex items-center gap-2 py-2 px-4 text-sm font-medium rounded-md shadow-md transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}`}
      style={{ backgroundColor: color, color: isDeliver ? 'white' : 'black' }}
      onClick={(e) => callphone(e)}
    >
      <IoCallOutline className="text-lg" /> {title}
    </button>
  )
}
