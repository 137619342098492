import React from 'react'
import NextOrderDetail from './NextOrderDetail'
import _ from 'lodash'
import { NumericFormat } from 'react-number-format'
import { IoEyeOutline, IoCloseOutline } from 'react-icons/io5'
import { formatDistance } from '../../helpers/FormatDistance'
import {
  FaCartShopping,
  FaTruckFast,
  FaCircleDollarToSlot,
} from 'react-icons/fa6'
// import PayOrder from '../payment/PayOrder'
import { Modal } from 'flowbite-react'

export default function ShipmentCosts({
  user,
  data,
  handleDeleteProductToCart,
  handleUpdateProductQuantity,
  openModal,
  setOpenModal,
}) {
  const [openDetail, setOpenDetail] = React.useState(false)
  const [selected, setSelected] = React.useState()

  return (
    <div>
      <Modal
        show={openModal}
        onClose={() => setOpenModal(false)}
        dismissible
        popup
        className="bg-white rounded-lg shadow-md"
      >
        <div className="flex flex-col gap-2 text-[15px] sm:text-[10.5px] p-5">
          <div className="flex justify-between items-center">
            <h5 className="font-bold text-gray-800">
              Détails du frais de livraison
            </h5>
            <IoCloseOutline
              onClick={() => setOpenModal(false)}
              className="text-[20px] cursor-pointer text-gray-600 hover:text-gray-800"
            />
          </div>
          {Object.keys(data).map((key) => (
            <div key={key} className="shadow rounded-[1rem] p-5 mt-4">
              <div className="w-full flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <span className="font-medium text-gray-800">Vendus par </span>
                  <span className="font-bold text-gray-900">
                    {data[key]?.seller?.username}{' '}
                  </span>
                  <span className="font-medium text-gray-800">situé à </span>
                  <span className="font-bold text-gray-900">
                    {formatDistance(data[key].distance)}
                  </span>
                </div>
                <div className="font-light text-gray-600">
                  Produits dans la commande:{' '}
                  <span className="font-bold text-gray-900">
                    {data[key].products.length}
                  </span>
                </div>

                <div className="flex gap-2 mt-4">
                  <div className="flex gap-2 items-center">
                    <FaCartShopping className="text-gray-500" />
                    <span className="font-medium text-gray-800">
                      Cout de la livraison:{' '}
                    </span>
                  </div>
                  <span className="font-roboto font-bold text-gray-900">
                    <NumericFormat
                      value={_.round(data[key].shipmentFees, -2)}
                      displayType={'text'}
                      thousandSeparator=" "
                    />{' '}
                    CDF
                  </span>
                </div>
                <div className="flex gap-2 mt-2">
                  <div className="flex gap-2 items-center">
                    <FaTruckFast className="text-gray-500" />
                    <span className="font-medium text-gray-800">
                      Prix total des produits:{' '}
                    </span>
                  </div>

                  <span className="font-roboto font-bold text-gray-900">
                    <NumericFormat
                      value={_.sum(data[key].prices)}
                      displayType={'text'}
                      thousandSeparator=" "
                    />{' '}
                    CDF
                  </span>
                </div>
                <div className="flex gap-2 mt-2">
                  <div className="flex gap-2 items-center">
                    <FaCircleDollarToSlot className="text-gray-500" />
                    <span className="font-medium text-gray-800">Total: </span>
                  </div>
                  <span className="font-roboto font-bold text-gray-900">
                    <NumericFormat
                      value={_.round(
                        _.sum(data[key].prices) + data[key].shipmentFees,
                        -2
                      )}
                      displayType={'text'}
                      thousandSeparator=" "
                    />{' '}
                    CDF
                  </span>
                </div>
                <div className="flex gap-2 mt-4">
                  <button
                    onClick={() => {
                      setSelected(data[key])
                      setOpenDetail(true)
                    }}
                    className="flex items-center p-2 gap-1 shadow text-[10.5px] text-primary rounded-[0.5rem] bg-blue-500 hover:bg-blue-600 text-white"
                  >
                    <IoEyeOutline />
                    <span className="font-medium text-white">
                      Voir les produits
                    </span>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Modal>

      <NextOrderDetail
        openModal={openDetail}
        setOpenModal={setOpenDetail}
        user={user}
        data={selected}
        handleDeleteProductToCart={handleDeleteProductToCart}
        handleUpdateProductQuantity={handleUpdateProductQuantity}
      />
    </div>
  )
}
