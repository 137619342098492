import React from 'react'
import {
  IoHelpCircleOutline,
  IoShieldHalfSharp,
  IoLogOutOutline,
  IoStorefrontOutline,
} from 'react-icons/io5'
import { BsBag } from 'react-icons/bs'
import { FaHandHoldingDollar } from 'react-icons/fa6'

import { useNavigate } from 'react-router-dom'
import blankImg from '../../assets/blank-profile.png'
import _ from 'lodash'
import Contact from '../Contact'

import Legals from '../Legals'
import { useSelector } from 'react-redux'
import { TbTruckDelivery } from 'react-icons/tb'
import LogOut from './LogOut'

const links = (
  currentUser,
  setContacting,
  setOpenLegals,
  navigate,
  deliver,
  shop
) => [
  !shop
    ? {
        name: <span className="">Ajouter son enseigne à uty</span>,
        description: 'Ajouter votre magasin sur uty et gerer tous vos produits',
        icon: <IoStorefrontOutline />,
        action: () => navigate('/create-shop'),
      }
    : {
        name: 'Ma boutique',
        description: 'Gerer toutes vos annonces en un seul endroit',
        icon: <IoStorefrontOutline />,
        action: () =>
          navigate(`/shop/me/${shop?._id}`, {
            state: { key: shop },
          }),
      },

  {
    name: 'Suivi de mes commandes',
    description: 'Voir tous mes achats et status de mes commandes',
    icon: <BsBag />,
    action: () => navigate(`/orders/me/${currentUser}`),
  },
  {
    name: 'Mes ventes et commandes à faire livrer',
    description: 'Voir tous mes ventes et commandes à valider et livrer',
    icon: <FaHandHoldingDollar />,
    action: () => navigate(`/orders/seller/${currentUser}`),
  },
  deliver?.isDeliver && {
    name: 'Livraisons à effectuer',
    description: 'Voir toutes les livraisons disponibles à effectuer',
    icon: <TbTruckDelivery />,
    action: () => navigate(`/shipments/pending`),
  },
  !deliver
    ? {
        name: 'Devenir livreur',
        description: 'Créer un compte pour livrer les produits',
        icon: <TbTruckDelivery />,
        action: () => navigate('/delivery/create'),
      }
    : null,
  deliver?.inValidating
    ? {
        name: 'Demande de livreur envoyée',
        description: 'En attente de reponse...',
        icon: <TbTruckDelivery />,
        action: () => navigate('/delivery/create'),
      }
    : null,
  {
    name: 'Aide',
    icon: <IoHelpCircleOutline />,
    action: () => setContacting(true),
  },
  {
    name: 'Mentions legales et confidentialité',
    icon: <IoShieldHalfSharp />,
    action: () => setOpenLegals(true),
  },
]

export default function MainCard({ setOpenMenu }) {
  const navigate = useNavigate()
  let currentUser = JSON.parse(localStorage.getItem('currentUser'))
  let shop = useSelector((state) => state.user.myShop)

  const deliver = useSelector((state) => state.user.currentDeliver)
  const [activeLink, setActiveLink] = React.useState(null)

  const [contacting, setContacting] = React.useState(false)
  const [openLegals, setOpenLegals] = React.useState(false)
  const [logout, setLogout] = React.useState(false)

  const handleLinkClick = (link) => {
    setActiveLink(link?.name)

    if (!link.component) {
      link?.action()
    }
  }

  return (
    <div className="relative w-full">
      <div
        onClick={() => setOpenMenu(false)}
        className="fixed inset-0 bg-black opacity-70"
      ></div>
      <div className="absolute right-0 top-0 w-[80vw] md:w-[30vw] h-[100vh] z-100 flex flex-col bg-white shadow-lg">
        {/* Header Section */}
        <div className="p-4 flex justify-between items-center border-b border-gray-100">
          <figcaption className="flex items-center">
            <img
              className="rounded-full w-10 h-10"
              src={
                _.isEmpty(currentUser?.image) ? blankImg : currentUser?.image
              }
              alt="profile"
            />
            <div className="ml-3 text-[rgba(34,34,34,0.78)]">
              <div className="font-bold text-lg">{currentUser?.username}</div>
            </div>
          </figcaption>
          <IoLogOutOutline
            className="text-gray-900 text-[28px] cursor-pointer"
            onClick={() => setLogout(true)}
          />
        </div>

        {/* Links Section */}
        <div className="flex-grow p-4 overflow-y-auto text-black">
          {_.without(
            links(
              currentUser?._id,
              setContacting,
              setOpenLegals,
              navigate,
              deliver,
              shop
            ),
            null
          ).map((link, index) => (
            <div
              key={index}
              className={`flex items-center gap-3 rounded-lg px-4 py-2 cursor-pointer hover:bg-blue-100 hover:text-gray-900 ${
                activeLink === link?.name ? 'bg-yellow-500 text-gray-900' : ''
              }`}
              onClick={() => handleLinkClick(link)}
            >
              <div className="text-[20px]">{link?.icon}</div>
              <div className="flex flex-col gap-1">
                <div className="font-semibold text-gray-900">{link?.name}</div>
                {link?.description && (
                  <span className="text-xs text-gray-900 font-normal">
                    {link?.description}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Footer Modals */}
        <Contact openModal={contacting} setOpenModal={setContacting} />
        <Legals openModal={openLegals} setOpenModal={setOpenLegals} />
        <LogOut openModal={logout} setOpenModal={setLogout} />
      </div>
    </div>
  )
}
