import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Label, Modal, TextInput } from 'flowbite-react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
// import axios from 'axios'
// import { BASE_URL } from '../../helpers/Root'
import { NumericFormat } from 'react-number-format'
import { IoCash } from 'react-icons/io5'
import { usePayOrdersMutation } from '../../services/paymentApi'

export default function PayOrder({ openModal, setOpenModal, toPay, cart }) {
  const navigate = useNavigate()
  const dropOffLocation = useSelector((state) => state.user.location)
  const currentUser = useSelector((state) => state.user.currentUser)
  const [phone, setPhone] = React.useState('')
  const [phoneError, setPhoneError] = React.useState('')

  const [payOrders, { isLoading, data }] = usePayOrdersMutation()

  const totalPrices = _.sum(_.flatten(_.values(toPay).map((p) => p.prices)))
  const totalShipmentFees = _.sum(_.values(toPay).map((p) => p.shipmentFees))

  const validatePhone = (phone) => {
    const validPrefixes = [
      '82',
      '83',
      '81',
      '84',
      '85',
      '89',
      '90',
      '92',
      '94',
      '95',
      '97',
      '98',
      '99',
    ]
    if (
      !phone.startsWith('0') &&
      validPrefixes.some((prefix) => phone.startsWith(prefix)) &&
      phone.length === 9
    ) {
      setPhoneError('')
      return true
    } else {
      setPhoneError('Numéro de téléphone invalide.')
      return false
    }
  }

  const pay = async (cart) => {
    if (validatePhone(phone)) {
      let order = {
        user: currentUser?._id,
        data: toPay,
        cart: cart?._id,
        dropOffLocation: dropOffLocation,
        amount: _.round(totalPrices + totalShipmentFees, -2),
        phone: phone,
      }

      await payOrders(order)
    }
  }

  if (typeof data == 'string') {
    navigate(`/invoice`, {
      state: { key: data },
    })
  }

  return (
    <>
      <Modal
        show={openModal}
        size="md"
        popup
        onClose={() => setOpenModal(false)}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="flex flex-col gap-3 px-5">
            <h3 className="text-xl font-roboto text-gray-500 dark:text-white">
              Paiement du panier
            </h3>

            <div>
              <div>
                <span className="text-sm font-light">Montant à payer:</span>
                <p className="text-[40px] font-bold text-gray-500">
                  <NumericFormat
                    value={_.round(totalPrices + totalShipmentFees, -2)}
                    displayType={'text'}
                    thousandSeparator=" "
                  />
                  fc
                </p>
              </div>
              <div className="max-w-md">
                <div className="mb-2 block">
                  <Label
                    htmlFor="phone"
                    value="Entrez votre numéro mobile money"
                  />
                </div>
                <TextInput
                  value={phone}
                  onChange={(e) => {
                    e.preventDefault()
                    setPhone(e.target.value)
                  }}
                  id="phone"
                  placeholder="821234567"
                  addon="243"
                />
                {phoneError && (
                  <p className="text-red-500 text-xs italic">{phoneError}</p>
                )}
              </div>
            </div>
            <div className="w-full mb-7">
              <button
                className="flex gap-2 items-center bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                onClick={(e) => {
                  e.preventDefault()
                  pay(cart)
                }}
              >
                <IoCash />
                {isLoading ? 'Paiement en cours...' : 'Payer la commande'}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
