import React from 'react'
import { Modal } from 'flowbite-react'
import { IoTrashSharp, IoEyeOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { NumericFormat } from 'react-number-format'

export default function NextOrderDetail({
  openModal,
  setOpenModal,
  data,
  user,
  handleDeleteProductToCart,
}) {
  const navigate = useNavigate()

  return (
    <Modal
      dismissible
      size="md"
      show={openModal}
      onClose={() => setOpenModal(false)}
      className="bg-white rounded-lg shadow-md"
    >
      <Modal.Header className="border-b border-gray-200">
        <span className="text-lg font-medium text-gray-900">
          Commande à {data?.seller?.username}
        </span>
      </Modal.Header>
      <Modal.Body className="p-5">
        <div className="flex flex-col gap-4">
          {data?.products?.map((product) => (
            <div
              key={product?.productId?._id}
              className="flex items-center border border-gray-200 rounded-lg p-3 shadow-sm hover:shadow transition duration-300"
            >
              <div className="mr-4">
                <img
                  src={product?.productId?.images[0]}
                  alt={product?.productId?.name}
                  className="h-20 w-20 rounded-md object-cover"
                />
              </div>
              <div className="flex-grow">
                <div className="text-lg font-medium text-gray-900">
                  {product?.productId?.name}
                </div>
                <div className="flex items-center text-gray-600">
                  <span className="mr-2">
                    {product?.quantity} x{' '}
                    <NumericFormat
                      value={product?.productId?.price}
                      displayType={'text'}
                      thousandSeparator=" "
                    />{' '}
                    {product?.productId?.currency
                      ? product?.productId?.currency?.name
                      : 'CDF'}
                  </span>
                </div>
                <div className="mt-2 flex items-center gap-2">
                  <button
                    className="flex items-center gap-1 px-3 py-2 text-sm font-medium text-blue-700 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300"
                    onClick={() =>
                      navigate(
                        `/announcements/${product?.productId?._id}/${product?.productId?.category}`
                      )
                    }
                  >
                    <IoEyeOutline className="mr-2" />
                    Voir
                  </button>
                  <button
                    className="flex items-center gap-1 px-3 py-2 text-sm font-medium text-red-700 bg-red-100 rounded-lg hover:bg-red-200 transition duration-300"
                    onClick={(e) => {
                      handleDeleteProductToCart(
                        e,
                        user,
                        product?.productId?._id
                      )
                    }}
                  >
                    <IoTrashSharp className="mr-2" />
                    Supprimer
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  )
}
