import { BrowserRouter } from 'react-router-dom'
import './App.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import MainRoutes from './routes/MainRoutes'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useStore } from './utils/Store'
import { getTokenFromFirebase, onMessageListener } from './firebase'
import _ from 'lodash'
import { updateToken } from './services/Users/update-token-firebase'
import { useDispatch } from 'react-redux'
import {
  setCurrentUser,
  setIsDeliver,
  setCurrentDeliver,
} from './features/user/user'
import socketIO from 'socket.io-client'
import { Helmet } from 'react-helmet'

import ToastMessage from './components/modal/ToastMessage'
import { setSocket } from './features/socket/socket'
import { BASE_URL_SOCKET } from './helpers/Root'
// import { setOnlineUsers } from './features/onlineUsers/online'
import CommentModal from './components/Comments/CommentModal'
import useCurrentLocation from './hooks/useCurrentLocation'
import { useGetUserShopQuery } from './services/shopApi'
import { setMyShop } from './features/user/user'

import useLocationUpdate from './hooks/useLocationUpdate'
import { useGetDeliverByUserQuery } from './services/deliverApi'
import { useGetCurrentUserQuery } from './services/userApi'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

ReactGA.initialize('G-CEJ3BQ6H38')

function App() {
  let currentUser = JSON.parse(localStorage.getItem('currentUser'))
  // let deliverId = JSON.parse(localStorage.getItem('deliver'))
  const socket = socketIO.connect(BASE_URL_SOCKET)
  const { data: user } = useGetCurrentUserQuery(currentUser?._id)

  const { data: deliver } = useGetDeliverByUserQuery(currentUser?._id)

  const dispatch = useDispatch()
  dispatch(setSocket(socket))
  dispatch(setCurrentUser(user))
  dispatch(setCurrentDeliver(deliver))
  dispatch(setIsDeliver(deliver?.isDeliver))
  deliver && localStorage.setItem('currentDeliver', JSON.stringify(deliver))
  //dispatch(checkTokenFirebase())

  const { data: shop } = useGetUserShopQuery(currentUser?._id)
  // console.log('shop', shop)
  dispatch(setMyShop(shop))

  useCurrentLocation()

  currentUser && useLocationUpdate(currentUser?._id)

  let tokenFirebase = useStore((state) => state.tokenFirebase)
  let updateTokenFirebase = useStore((state) => state.updateTokenFirebase)
  let haveNewMessage = useStore((state) => state.haveNewMessage)
  let updateHaveNewMessage = useStore((state) => state.updateHaveNewMessage)
  let payload = useStore((state) => state.payload)
  let updatePayload = useStore((state) => state.updatePayload)

  //Conncted user in socket
  useEffect(() => {
    socket.emit('addUser', currentUser?._id)
    // socket.on('getUsers', (users) => {
    //   dispatch(setOnlineUsers(users))
    // })
  }, [currentUser])

  useEffect(() => {
    socket.on('receiveMessage', (data) => {
      updatePayload(data)
      updateHaveNewMessage(true)
      return () => {
        socket.off('receiveMessage')
      }
    })
  }, [socket])

  useEffect(() => {
    if (currentUser) {
      getTokenFromFirebase(updateTokenFirebase)
    } else {
      onMessageListener(updatePayload, updateHaveNewMessage)
    }
  }, [currentUser])

  useEffect(() => {
    if (!_.isEmpty(tokenFirebase)) {
      updateToken(currentUser, tokenFirebase)
    }
  }, [tokenFirebase])

  return (
    <>
      <Helmet>
        <title>uty</title>
        <meta
          name="description"
          content="La meilleure plateforme ecommerce de la république democratique du congo"
        />
        <meta
          name="google-site-verification"
          content="-7XRvk9GW88BO4zSBkpxEvrRDRKcqlKXJFzha8C-zds"
        />
      </Helmet>
      <BrowserRouter>
        <div className="relative">
          <div className="">
            <CommentModal
              haveNewMessage={haveNewMessage}
              payload={payload}
              updateHaveNewMessage={updateHaveNewMessage}
            />
          </div>
        </div>

        {haveNewMessage && (
          <div className="">
            <ToastMessage
              haveNewMessage={haveNewMessage}
              payload={payload}
              updateHaveNewMessage={updateHaveNewMessage}
            />
          </div>
        )}
        <MainRoutes currentUser={currentUser} />
      </BrowserRouter>
    </>
  )
}

export default App
