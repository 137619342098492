import { Button, Modal } from 'flowbite-react'
import React, { useState } from 'react'
// import LottieLoader from '../../components/LottieLoader'
// import indexLoader from '../../assets/sellerAnnouncementLoader.json'
import OrderShipmentMap from '../../components/orders/OrderShipmentMap'
import ShipmentDatas from '../../components/Shipments/ShipmentDatas'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useAcceptShipmentMutation } from '../../services/shipmentApi'

export default function ShipmentOverview({
  openModal,
  setOpenModal,
  shipment,
}) {
  const navigate = useNavigate()
  const currentDeliver = useSelector((state) => state.user.currentDeliver)
  const [acceptShipment, { isLoading }] = useAcceptShipmentMutation()

  const [direction, setDirection] = useState({})
  const [trajectory, setTrajectory] = useState({
    distance: '',
    duration: '',
  })

  const getDirection = async (shipment) => {
    let pickUpLocation = shipment?.order?.pickUpLocation?.coordinates
    let dropOffAddress = shipment?.order?.dropOffLocation?.coordinates
    let deliverLocation = shipment?.deliver?.user?.location?.coordinates

    await axios
      .get(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${pickUpLocation[0]},${pickUpLocation[1]};${dropOffAddress[0]},${dropOffAddress[1]}?access_token=pk.eyJ1IjoidXR5LXdlYiIsImEiOiJjbHN0NTNrdXYxdDJiMmtuMXAxZHA2anM1In0.GRa3DMA8Q2f7x-iRij1ldw&geometries=geojson`
      )
      .then((response) => setDirection(response))

    let result =
      shipment?.status !== 'in delivery'
        ? await axios
            .get(
              `https://api.mapbox.com/directions/v5/mapbox/driving/${pickUpLocation[0]},${pickUpLocation[1]};${dropOffAddress[0]},${dropOffAddress[1]}?access_token=pk.eyJ1IjoidXR5LXdlYiIsImEiOiJjbHN0NTNrdXYxdDJiMmtuMXAxZHA2anM1In0.GRa3DMA8Q2f7x-iRij1ldw&geometries=geojson`
            )
            .then((response) => setDirection(response))
        : await axios.get(
            `https://api.mapbox.com/directions/v5/mapbox/driving/${dropOffAddress[0]},${dropOffAddress[1]};${deliverLocation[0]},${deliverLocation[1]}?access_token=pk.eyJ1IjoidXR5LXdlYiIsImEiOiJjbHN0NTNrdXYxdDJiMmtuMXAxZHA2anM1In0.GRa3DMA8Q2f7x-iRij1ldw&geometries=geojson`
          )

    return result
  }

  const handleAcceptShipment = async (deliver, shipmentId) => {
    await acceptShipment({ deliver, shipment: shipmentId }).then(() =>
      navigate(`/shipments/${shipment?._id}`)
    )
  }

  console.log('ship order', shipment)

  React.useEffect(() => {
    getDirection(shipment)
  }, [shipment])

  React.useEffect(() => {
    setTrajectory({
      distance: direction?.data?.routes[0]?.distance,
      duration: direction?.data?.routes[0]?.duration,
    })
  }, [direction])

  return (
    <>
      <Modal show={openModal} onClose={() => setOpenModal(false)} size="md">
        <Modal.Header>Aperçu de la course</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <ShipmentDatas trajectory={trajectory} />
            <div className="">
              <OrderShipmentMap
                pickUpLocation={shipment?.order?.pickUpLocation?.coordinates}
                dropOffAddress={shipment?.order?.dropOffLocation?.coordinates}
                deliverLocation={shipment?.deliver?.user?.location?.coordinates}
                setTrajectory={setTrajectory}
                direction={direction}
                shipmentStatus={shipment?.status}
                shipment={shipment}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="px-4 py-2 rounded-md bg-blue-500 text-white font-medium hover:bg-blue-600 transition duration-300"
            onClick={(e) => {
              e.preventDefault()
              handleAcceptShipment(currentDeliver, shipment?._id)
            }}
          >
            {isLoading ? 'Accepter...' : 'Accepter la livraison'}
          </button>
          <Button color="gray" onClick={() => setOpenModal(false)}>
            Ignorer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
