import React from 'react'
import { useSelector } from 'react-redux'
import { useCollectMutation } from '../../services/shipmentApi'
import { FaShippingFast } from 'react-icons/fa'

function StartShipment({ shipment }) {
  let currentUser = useSelector((state) => state.user.currentUser)
  let currentDeliver = useSelector((state) => state.user.currentDeliver)

  const [collect, { isLoading, data }] = useCollectMutation()

  console.log(
    'datas',
    data,
    currentDeliver,
    shipment?.order?.seller?._id === currentUser?._id
  )

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      {/* Afficher le bouton seulement si l'utilisateur est le livreur actuel */}
      {shipment?.deliver?._id === currentDeliver?._id && (
        <div>
          {/* Vérification si la collecte n'a pas encore eu lieu */}
          {!shipment?.isCollect && (
            <button
              className="bg-blue-600 flex items-center gap-2 px-4 py-2 text-white text-sm font-medium rounded-md shadow-md hover:bg-blue-700 transition-all duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
              onClick={(e) => {
                e.preventDefault()
                collect(shipment._id)
              }}
            >
              <FaShippingFast className="text-lg" />
              {isLoading ? 'Lancement...' : 'Lancer la livraison'}
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default StartShipment
