import React from 'react'
import Rating from 'react-rating'
import { FaRegStar, FaStar } from 'react-icons/fa6'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import CallUser from './CallUser'
import thisUser from './ShipmentContact'

const UserRating = ({ rating }) => {
  return (
    <Rating
      initialRating={rating}
      emptySymbol={<FaRegStar className="text-[18px] text-gray-400" />}
      fullSymbol={<FaStar className="text-[18px] text-yellow-400" />}
      readonly={true}
    />
  )
}

function ShipmentHeader({ shipment }) {
  const currentUser = useSelector((state) => state.user.currentUser)
  let contact = thisUser(shipment, currentUser)

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <div className="flex gap-8 items-center">
        {/* Information du livreur */}
        <div className="flex items-center gap-3">
          <img
            src={shipment?.deliver?.picture}
            className="h-10 w-10 rounded-full shadow-sm"
            alt=""
          />
          <div className="flex flex-col">
            <span className="text-gray-700 font-semibold">
              Livrée par {shipment?.deliver?.user?.username}
            </span>
            <UserRating rating={_.mean(shipment?.deliver?.rating)} />
          </div>
        </div>

        {/* Section Contact */}
        {thisUser && (
          <div className="flex gap-4">
            {contact?.map((item, index) => (
              <div key={index}>
                <CallUser
                  user={item?.contact}
                  title={item?.title}
                  color={item?.color}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default ShipmentHeader
