import React from 'react'
import { useSelector } from 'react-redux'
import { useDropOffDeliveryMutation } from '../../services/shipmentApi'
import RatingShipment from '../../components/Shipments/Rating'

function StartShipment({ shipment }) {
  let currentUser = useSelector((state) => state.user.currentUser)
  let currentDeliver = useSelector((state) => state.user.currentDeliver)

  const [dropOffDelivery, result] = useDropOffDeliveryMutation()
  const [rating, setRating] = React.useState(false)

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg">
      {/* Bouton pour le client pour noter la livraison */}
      {shipment?.order?.customer?._id === currentUser?._id && (
        <div className="mb-4">
          {!shipment?.isRated && shipment?.status === 'delivered' && (
            <button
              className="border border-blue-500 text-blue-500 font-medium rounded-md px-4 py-2 hover:bg-blue-500 hover:text-white transition-all duration-300 ease-in-out"
              onClick={(e) => {
                e.preventDefault()
                setRating(true)
              }}
            >
              Noter la livraison
            </button>
          )}
        </div>
      )}

      {/* Bouton pour le livreur pour valider la livraison */}
      {shipment?.deliver?._id === currentDeliver?._id && (
        <div className="mb-4">
          {!shipment?.isDropOff && (
            <button
              className="bg-blue-600 flex items-center gap-2 px-4 py-2 text-white font-medium rounded-md shadow-md hover:bg-blue-700 transition-all duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
              onClick={(e) => {
                e.preventDefault()
                dropOffDelivery(shipment._id)
              }}
            >
              {result?.isLoading
                ? 'Validation du dépôt...'
                : 'Valider la livraison'}
            </button>
          )}
        </div>
      )}

      {/* Modal de notation */}
      <RatingShipment openModal={rating} setOpenModal={setRating} />
    </div>
  )
}

export default StartShipment
