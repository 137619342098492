import { FaCartArrowDown } from 'react-icons/fa'

const links_options = (cart) => {
  return [
    {
      title: 'Panier',
      icon: (
        <div className="relative bg-green">
          {cart?.length > 0 && (
            <div
              className={` bg-red-500 absolute top-[-6px] left-[17px] text-[9px] py-[1px] text-white ${cart?.length > 9 ? 'px-[3px]' : 'px-[5px]'}  rounded-full`}
            >
              {cart?.length}
            </div>
          )}
          <FaCartArrowDown className="text-[25px] text-blue-600" />
        </div>
      ),
      url: '/',
    },
    // {
    //     title: 'Panier',
    //     icon: (
    //       <div className="relative bg-green">
    //         {cart?.length > 0 && (
    //           <div
    //             className={` bg-red-500 absolute top-[-6px] left-[17px] text-[9px] py-[1px] text-white ${cart?.length > 9 ? 'px-[3px]' : 'px-[5px]'}  rounded-full`}
    //           >
    //             {cart?.length}
    //           </div>
    //         )}
    //         <FaCartArrowDown className="text-[25px] text-primary" />
    //       </div>
    //     ),
    //     url: '/',
    //   },
  ]
}

export { links_options }
