import React from 'react'
import OrderItem from './OrderItem'
import {
  useAcceptOrderMutation,
  useRefuseOrderMutation,
} from '../../services/orderApi'
import { BsBagX } from 'react-icons/bs'

function OrdersToValidate({ orders }) {
  const [acceptOrder] = useAcceptOrderMutation()
  const [refuseOrder] = useRefuseOrderMutation()

  const validateOrder = async (order, location) => {
    await acceptOrder({ order, location })
  }

  const declineOrder = async (order) => {
    await refuseOrder(order)
  }

  return (
    <div className="grid grid-cols-1  lg:grid-cols-2 gap-4">
      {orders?.length === 0 ? (
        <div className="flex flex-col gap-5 justify-center items-center pt-20 col-span-full">
          <BsBagX className="text-[50px] text-slate-500" />
          <div>{"Vous n'avez pas de nouvelles commandes!!"}</div>
        </div>
      ) : (
        orders
          ?.map((order) => (
            <div key={order?._id} className="">
              <OrderItem
                order={order}
                acceptOrder={validateOrder}
                refuseOrder={declineOrder}
              />
            </div>
          ))
          .reverse()
      )}
    </div>
  )
}

export default OrdersToValidate
