import React, { useEffect } from 'react'
// import axios from 'axios';
import mapboxgl from 'mapbox-gl'
import ReactDOM from 'react-dom'
// import FontawesomeMarker from 'mapbox-gl-fontawesome-markers'
import { RiStore3Fill } from 'react-icons/ri'
import { FaTruckFast } from 'react-icons/fa6'
import { BsGeoFill } from 'react-icons/bs'

const elPickUp = document.createElement('div')
ReactDOM.createRoot(elPickUp).render(
  <div className="rounded-full bg-primary text-white flex flex-col justify-center items-center p-2">
    <RiStore3Fill className="" size={25} />
    <span className="">Vendeur</span>
  </div>
)

const elDropOff = document.createElement('div')
ReactDOM.createRoot(elDropOff).render(
  <BsGeoFill className="animate-bounce" size={44} />
)

const deliver = document.createElement('div')
ReactDOM.createRoot(deliver).render(
  <div className="bg-secondary text-primary rounded-full flex flex-col p-2 justify-center items-center">
    <FaTruckFast className="" size={25} />
    <span className="">Livreur</span>
  </div>
)

mapboxgl.accessToken =
  'pk.eyJ1IjoidXR5LXdlYiIsImEiOiJjbHN0NTNrdXYxdDJiMmtuMXAxZHA2anM1In0.GRa3DMA8Q2f7x-iRij1ldw'

const OrderShipmentMap = ({
  pickUpLocation,
  dropOffAddress,
  deliverLocation,
  direction,
  // shipmentStatus,
  shipment,
}) => {
  console.log('addresses:', {
    pick: pickUpLocation,
    drop: dropOffAddress,
    deliver: deliverLocation,
    shipment: shipment,
  })

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [dropOffAddress[0], dropOffAddress[1]],
      zoom: 11,
    })

    map.on('load', async function () {
      // let direction = await axios.get(
      //   `https://api.mapbox.com/directions/v5/mapbox/driving/${pickUpLocation[0]},${pickUpLocation[1]};${dropOffAddress[0]},${dropOffAddress[1]}?access_token=pk.eyJ1IjoidXR5LXdlYiIsImEiOiJjbHN0NTNrdXYxdDJiMmtuMXAxZHA2anM1In0.GRa3DMA8Q2f7x-iRij1ldw&geometries=geojson`
      // );

      const route = direction?.data?.routes[0]?.geometry
      //const distance = direction.data.routes[0].distance / 1000;

      // console.log(`direction: ${direction}`);

      map.addSource('route', {
        type: 'geojson',
        data: route,
      })

      map.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#669bbc',
          'line-width': 6,
        },
      })

      // new FontawesomeMarker({
      //   icon: 'fa-regular fa-pizza-slice',
      //   iconColor: 'white',
      //   color: '#fa7132',
      // })
      //   .setLngLat([pickUpLocation[0], pickUpLocation[1]])
      //   .setPopup(
      //     new mapboxgl.Popup({ offset: 25 }).setText('Localisation du vendeur')
      //   )
      //   .addTo(map)

      new mapboxgl.Marker({
        draggable: true,
        element: elPickUp,
      })
        .setLngLat([pickUpLocation[0], pickUpLocation[1]])
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }).setText('Localisation du vendeur')
        )
        .addTo(map)

      // new FontawesomeMarker({
      //   icon: 'fa-regular fa-coffee',
      //   iconColor: 'white',
      //   color: 'blue',
      // })
      //   .setLngLat([dropOffAddress[0], dropOffAddress[1]])
      //   .setPopup(
      //     new mapboxgl.Popup({ offset: 25 }).setText('Point de livraison')
      //   )
      //   .addTo(map)

      new mapboxgl.Marker({
        element: elDropOff,
      })
        .setLngLat([dropOffAddress[0], dropOffAddress[1]])
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }).setText('Point de livraison')
        )
        .addTo(map)

      // new FontawesomeMarker({
      //   icon: 'fa-solid fa-truck-fast',
      //   iconColor: 'white',
      //   color: 'green',
      // })
      //   .setLngLat([deliverLocation[0], deliverLocation[1]])
      //   .setPopup(
      //     new mapboxgl.Popup({ offset: 25 }).setText('Localisation du livreur')
      //   )
      //   .addTo(map)

      deliverLocation &&
        new mapboxgl.Marker({
          element: deliver,
          draggable: true,
        })
          .setLngLat([deliverLocation[0], deliverLocation[1]])
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }).setText(
              'Localisation du livreur'
            )
          )
          .addTo(map)
    })

    return () => map.remove()
  }, [pickUpLocation, dropOffAddress, deliverLocation])

  return (
    <div className="w-[100%]">
      <div
        id="map"
        className="rounded-[0.5rem] h-[50vh] w-[90%] lg:w-[27.5vw]"
      />
    </div>
  )
}

export default OrderShipmentMap
